import { ThankYouPageMessagesType } from 'src/types';

export const thankYouPageMessages: ThankYouPageMessagesType = {
  header: (name: string) => `${name}, you're almost there!`,
  subtext1:
    "We've set up your new business account. To start making global transfers we'll just need a little more detail to verify you and your business.",
  subtext2: "To complete the process, you'll need:",
  subtext3:
    "Don't have these details on hand or just not a good time right now?",
  subtext4:
    "Don't worry, you can return anytime from the dashboard to complete later",
  personalDocuments: [],
  personalDocumentsForPassfortOnboarding: [],
  businessDocuments: [],
};
