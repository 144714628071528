import { ValidationMessagesType } from 'src/types';

interface FieldErrorOptions {
  opposite?: boolean;
  selector?: boolean;
}

const getFieldRequired = (
  label: string,
  options?: FieldErrorOptions
): string => {
  const opposite = options?.opposite;
  const selector = options?.selector;
  const vowels = ['a', 'e', 'i', 'o', 'u'];

  const letter = label[0].toLowerCase();
  const vowel = vowels.includes(letter);

  const action = selector ? 'select' : 'enter';

  return `Please ${action} ${vowel && !opposite ? 'an' : 'a'} ${label}`;
};

const validationMessages: ValidationMessagesType = {
  globalRequired: 'Please fill in all the required fields',
  fieldRequired: 'Field is required',
  fieldInvalid: 'Field is not valid',
  minFieldLength: function (minLength): string {
    return `Please enter at least ${minLength} characters.`;
  },
  maxFieldLength: function (maxLength): string {
    return `Max field length is ${maxLength} characters`;
  },
  DOB: {
    minYears: 'You must be at least 18 years old to open an OFX account',
    maxYears: 'Please enter a valid year.',
    futureDate: 'Please enter a date in the past',
    invalidDate: 'Please enter a valid date',
    fieldRequired: getFieldRequired('date of birth'),
  },
  firstname: {
    invalid:
      'Please enter a valid first name and remove any numbers or symbols.',
    maxFieldLength: (maxLength: number): string =>
      `First name must not exceed ${maxLength} characters.`,
    fieldRequired: getFieldRequired('firstname'),
  },
  middlename: {
    invalid:
      'Please enter a valid middle name and remove any numbers or symbols.',
    maxFieldLength: (maxLength: number): string =>
      `Middle name must not exceed ${maxLength} characters.`,
    fieldRequired: getFieldRequired('middlename'),
  },
  lastname: {
    invalid:
      'Please enter a valid last name and remove any numbers or symbols.',
    maxFieldLength: (maxLength: number): string =>
      `Last name must not exceed ${maxLength} characters.`,
    fieldRequired: getFieldRequired('lastname'),
  },
  email: {
    invalid: 'Please enter a valid email address.',
    maxFieldLength: (maxLength: number): string =>
      `Work email must not exceed ${maxLength} characters.`,
    exists: 'Looks like you already have an account with us. Please log in.',
    fieldRequired: getFieldRequired('email'),
  },
  currency: {
    unsupportedFrom:
      'We currently do not support transfers from this currency.',
    unsupportedTo: 'We currently do not support transfers to this currency.',
    currencyMatch:
      'We currently do not support same currency transfers online.',
    fieldRequired: getFieldRequired('currency', { selector: true }),
    invalidListFrom: (labels: string[]): string => {
      if (labels.length === 1) {
        return `We currently do not support transfers from ${labels[0]}.`;
      }
      const last = labels.pop();
      return `We currently do support transfers from ${labels.join(
        ', '
      )} and ${last}.`;
    },
    invalidListTo: (labels: string[]): string => {
      if (labels.length === 1) {
        return `We currently do not support transfers to ${labels[0]}.`;
      }
      const last = labels.pop();
      return `We currently do support transfers to ${labels.join(
        ', '
      )} and ${last}.`;
    },
  },
  transferRange: {
    fieldRequired: getFieldRequired('transfer range', { selector: true }),
  },
  business: {
    maxFieldLength: (maxLength: number): string =>
      `Business name must not exceed ${maxLength} characters.`,
    fieldRequired: getFieldRequired('business name'),
  },
  businessType: {
    fieldRequired: getFieldRequired('business type', { selector: true }),
  },
  businessNumber: {
    maxFieldLength: (maxLength: number): string =>
      `Business number must not exceed ${maxLength} characters.`,
    fieldRequired: getFieldRequired('business number'),
  },
  relatedEntity: {
    fieldRequired: 'Please select an option',
  },
  username: {
    exists: 'This username already exists. If this is you, please log in.',
    fieldRequired: getFieldRequired('username', { opposite: true }),
  },
  license: {
    invalid: 'Please enter a valid driver licence number.',
    maxFieldLength: (maxLength): string =>
      `Driver licence number must not exceed ${maxLength} characters.`,
    fieldRequired: getFieldRequired('license'),
  },
  share: {
    minimum: 'Please enter a percentage of 1% or above.',
    spentaMinimum: 'Please enter a percentage above 0%.',
    maximum: 'Percentage of share must not exceed 100%.',
    fieldRequired: getFieldRequired('percentage'),
  },
  position: {
    maxFieldLength: (maxLength: number): string =>
      `Title/position in the business must not  exceed ${maxLength} characters.`,
    fieldRequired: getFieldRequired('title/position'),
  },
  ssn: {
    invalid: 'Please enter a valid social security number.',
    fieldRequired: getFieldRequired('ssn'),
  },
  phone: {
    invalid: 'Invalid Number',
    invalidResponse: 'Please enter a valid phone number.',
    fieldRequired: getFieldRequired('phone number'),
  },
  address: {
    fieldRequired: getFieldRequired('address'),
    buildingName: {
      invalid: 'Please enter a valid building name and remove any symbols.',
      maxFieldLength: (maxLength: number): string =>
        `Building name must not exceed ${maxLength} characters.`,
    },
    unitNo: {
      invalid: 'Please enter a valid unit number and remove any symbols.',
      maxFieldLength: (maxLength: number): string =>
        `Unit no. must not exceed ${maxLength} characters.`,
    },
    streetNo: {
      invalid: 'Please enter a valid street number and remove any symbols.',
      maxFieldLength: (maxLength: number): string =>
        `Street no. must not exceed ${maxLength} characters.`,
      fieldRequired: getFieldRequired('street number'),
    },
    streetName: {
      invalid: 'Please enter a valid street name and remove any symbols.',
      maxFieldLength: (maxLength: number): string => `	
      Street name must not exceed ${maxLength} characters.`,
      fieldRequired: getFieldRequired('street name'),
    },
    streetType: {
      fieldRequired: getFieldRequired('street type', { selector: true }),
    },
    state: {
      fieldRequired: getFieldRequired('state', { selector: true }),
    },
    city: {
      invalid: 'Please enter a valid city or town and remove any symbols.',
      maxFieldLength: (maxLength: number): string =>
        `City or suburb must not exceed ${maxLength} characters.`,
      fieldRequired: getFieldRequired('city or suburb'),
    },
    zip: {
      invalid: 'Please enter a valid postcode and remove any symbols.',
      maxFieldLength: (maxLength: number): string =>
        `ZIP must not exceed ${maxLength} characters.`,
      fieldRequired: getFieldRequired('zip/postcode'),
    },
  },
  securityAnswer: {
    maxFieldLength: (maxLength: number): string => `	
    Security answer must not exceed ${maxLength} characters.`,
    fieldRequired: getFieldRequired('security answer'),
  },
  country: {
    invalid: (label: string): string =>
      `We currently do not offer services to businesses in ${label}.`,
    invalidList: (labels: string[]): string => {
      if (labels.length === 1) {
        return `We currently do not offer services to businesses in ${labels[0]}.`;
      }
      const last = labels.pop();
      return `We currently do not offer services to businesses in ${labels.join(
        ', '
      )} and ${last}.`;
    },
    fieldRequired: getFieldRequired('country', { selector: true }),
  },
  industry: {
    fieldRequired: getFieldRequired('industry', { selector: true }),
  },
  industryGroup: {
    fieldRequired: getFieldRequired('industry group', { selector: true }),
  },
  password: {
    fieldRequired: getFieldRequired('password'),
  },
  securityQuestion: {
    fieldRequired: getFieldRequired('security question', { selector: true }),
  },
};

export default validationMessages;
