import { I18n } from 'src/types';
import validationMessages from '../../validationMessages';
import defaults from '../../soleTraderDefaults';
import { thankYouPageMessages } from './../../thankyouMessages';

const thankYouPage = {
  personalDocuments: [
    {
      text: 'Photo ID',
      highlight: 'if we fail to electronically verify the identity',
    },
  ],
  businessDocuments: [
    {
      text: 'Official document confirming you operate your own business as an individual and are self-employed',
    },
    {
      text: 'A recent transactional bank statement that displays your trading name (if applicable)',
    },
    {
      text: 'Official document confirming your ‘trading as’ name',
      highlight: 'if you operate under a trading name',
    },
  ],
};

const i18n: I18n = {
  locale: 'uk',
  genericServerErrorText: 'Sorry, something went wrong. Please try again.',
  dateFormat: 'dd/MM/yyyy',
  dateOfBirth: {
    label: 'Date of birth',
  },
  docPopover: {
    docPopoverLink: 'Why is verification  required?',
    docPopoverCloseButtonText: 'Thanks, I understand',
    docPopoverHeading:
      'Important information about procedures for opening a new account',
    docPopoverBody1:
      'To help the government fight the funding of terrorism and money laundering activities, federal law requires all financial institutions to obtain, verify, and record information that identifies each person who opens an account.',
    docPopoverBody2:
      'What this means for you: When you open an account, we will ask for your name, address, date of birth, and other information that will allow us to identify you. We may also ask to see your driver’s license or other identifying documents.',
  },
  shareholdersPage: {
    header: 'About your business directors & shareholders',
    subHeader:
      'We are a fully regulated business and take security seriously, so we need to verify your business & your business stakeholders.',
    buttonLabel: 'Continue',
    newOnboardingSpinner: [
      {
        firstLineMessage: "We're gathering your company information.",
        secondLineMessage: 'This could take a minute...',
      },
      {
        firstLineMessage: "We're gathering your company information.",
        secondLineMessage: 'Almost there...',
      },
    ],
    spentaErrorDialog: {
      header: "Sorry, we're unable to proceed",
      subHeader:
        'A system issue has prevented us from processing your request. Please click <a href="https://www.ofx.com/en-us/contact-us/">here</a> and reach out to an OFXpert in your region.',
      button: 'GO TO DASHBOARD',
    },
  },
  registrationPage: {
    header: 'Start making secure transfers today',
    subHeader: 'Are you moving money for personal or business purposes?',
    referredByText: "You've been referred by",
    personalTile: {
      title: 'Personal',
      subTitle: 'For individuals who need to move money globally',
    },
    businessTile: {
      title: 'Business',
      subTitle:
        'For companies, accountants, e-commerce businesses or sole traders',
    },
    firstNameLabel: 'First name',
    lastNameLabel: 'Last name',
    emailLabel: 'Business email',
    countryLabel: 'Country of registration',
    businessTypeLabel: 'Business type',
    currencyHeader:
      'Tell us your needs and we’ll identify the best solution for you',
    currencySubHeader: 'What currencies are you likely to transfer?',
    fromCurrencyLabel: 'From currency',
    toCurrencyLabel: 'To currency',
    transferRangeHeader:
      'What amount do you expect to transfer yearly in your local currency?',
    transferRange1: '0 - 500,000',
    transferRange2: '500,000 - 1,000,000',
    transferRange3: '1,000,000 - 5,000,000',
    transferRange4: '5,000,000+',
    newTransferRange: {
      range1: '0 - 10,000',
      range2: '11,000 - 50,000',
      range3: '51,000 - 100,000',
      range4: '101,000 - 500,000',
      range5: '501,000 - 750,000',
      range6: '751,000 - 1,000,000',
      range7: '1,000,000 - 5,000,000',
      range8: '5,000,000+',
    },
    userAgreement: [
      {
        link: 'https://www.ofx.com/en-gb/legal/privacy-policy/',
        text: 'I have read and agree to the',
        linkText: 'OFX Privacy Policy',
      },
    ],
    accountancyPractice: {
      text: "I'm an accountant and want to use OFX for my clients",
      subTitle:
        'Please complete the form below using your practice credentials',
    },
  },
  businessDetailsPage: {
    header: 'Tell us a bit about your business',
    subHeader:
      'We are a fully regulated business and take security seriously, so we need to verify your business details.',
    aboutBusinessHeading: 'About your business',
    securityAnswer: 'Security answer',
    securityQuestion: 'Security question',
    username: 'Username',
    password: 'Password',
    createBusinessHeading: 'Create your business account',
    registeredBusinessAddress: 'Registered business address',
    autoCompanySearch: 'Search business name or business number',
    alternativeAddress:
      'The trading/operating address of the business is different to registered business address',
    tradingAddress: 'Trading/operating address',
    businessWebsite: 'Business website (optional)',
    businessIndustry: 'Business industry',
    businessIndustryGroup: 'Business industry group',
    businessIndustryDivision: 'Business industry',
    businessName: 'Business name',
    businessNumber: 'Company registration number',
  },
  aboutPage: {
    header: 'Tell us a bit about yourself',
    subHeader:
      'We are a fully regulated business and take security seriously, so we need to verify your personal details.',
    aboutYouHeading: 'Your details',
    errorHeader:
      'Unfortunately we cannot service your business needs at the current time.',
    button: 'Go to OFX',
    userAgreement: {
      description:
        'By clicking the Submit button, I confirm I hold the title/position above; agree and consent to the [AGREEMENT] and [PRIVACY]; represent and warrant that I am duly authorised to sign on behalf of the business',
      agreementLabel: 'Customer Agreement',
      policyLabel: 'Privacy Policy',
      agreementLink: 'https://www.ofx.com/en-gb/legal/customer-agreement/',
      policyLink: 'https://www.ofx.com/en-gb/legal/privacy-policy/',
    },
    firstName: 'First name',
    middleName: 'Middle name (if any)',
    lastName: 'Last name',
    email: 'Business email',
    phone: 'Phone number',
    mobile: 'Mobile number',
    address: 'Residential address',
    jobTitle: 'Which of the below best describes your position',
    percentageOwned: 'Percentage of shares you own',
  },
  digitalKycForm: {
    ...defaults.digitalKycForm,
  },
  thankYouPage: {
    ...thankYouPageMessages,
    ...thankYouPage,
  },
  uploadPage: {
    ...defaults.uploadPage,
    businessDocuments: {
      heading: 'Business documents',
      displayAsList: false,
      items: [
        {
          description:
            'Official document confirming you operate your own business as an individual and are self-employed. This can be:',
          listItems: [
            'Individual tax return',
            'Government-issued letter (such as HMRC Unique Taxpayer Reference (UTR) letter)',
          ],
        },
        {
          description:
            'A recent transactional bank statement that displays your trading name (if applicable).',
        },
        {
          description:
            "If you operate under a trading name, we'll need an official document confirming your ‘trading as’ name.",
        },
      ],
    },
  },
  addShareholdersBox: {
    topMessage:
      'Add all shareholders who own 25% or more, plus the directors of your business.',
    bottomMessage:
      'If no single shareholder owns 25% or more, add all directors with a controlling interest. You can add up to 5 shareholders/directors.',
    buttonLabel: 'Add director or shareholder',
  },
  // TODO: clean up unnecessary i18n tokens e.g. "shareholdersForm" isn't used by Sole Trader Journey
  shareholdersForm: {
    header: 'Director or shareholder details',
    subHeader:
      'Shareholders who own 25% or more, plus the directors of your business.',
    firstNameLabel: 'First name',
    lastNameLabel: 'Last name',
    emailLabel: 'Email',
    mobileLabel: 'Phone number',
    addressLabel: 'Residential address',
    submitButtonLabel: 'Submit',
    discardButtonLabel: 'Discard',
    describePositionLabel:
      'Which of the below best describes the position of the shareholder/director:',
    companyMemberRoles: {
      directorAndShareHolder: 'A director & shareholder',
      primaryUser: 'Primary user',
      director: 'Director only',
      beneficialOwner: 'Shareholder only',
      complexShareholderMessage:
        'Percentage of shares owned is pre-calculated based on multiple ownerships. You can proceed to add this entry and speak to an OFXpert at a later time, if any change is required.',
    },
    jobTitleLabel: 'What is their position in the business',
    sharesOwnedLabel: 'Percentage of shares they own',
    manualAddressFieldSetTitle:
      'Enter the Shareholder/Director residential address',
  },
  manualAddress: {
    fieldSetTitle: 'Enter the Shareholder/Director residential address',
  },
  userDetailsCard: {
    verified: 'VERIFIED',
    verifying: 'VERIFYING',
    unableToVerify: 'DOCUMENTATION NEEDED',
    primaryUser: 'PRIMARY USER',
    address: 'Residential address',
    dob: 'DOB',
    phone: 'Phone',
    email: 'Email',
    sharesOwned: 'Shares owned',
    notAvailable: 'n/a',
  },
  userDetailForm: {
    header: 'Your details',
    firstNameLabel: 'First name',
    lastNameLabel: 'Last name',
    emailLabel: 'Business email',
    mobileLabel: 'Phone number',
    addressLabel: 'Residential address',
    ssnLabel: 'Social Security number',
    ssnPopoverOpenButtonText: 'Why do we need this?',
    ssnPopoverCloseButtonText: 'Thanks, I understand',
    ssnPopoverHeading: 'Why does OFX require your SSN?',
    ssnPopoverBody:
      'US regulations require the collection your Social Security number to ensure the security of your account and the safety of your money transferred with us. All information provided to OFX is stored securely and kept private.',
    manualAddressFieldSetTitle: 'Enter your residential address',
  },
  validationMessages,
  primaryUserRoles: {
    shareholderOfficer: 'I am a shareholder and director',
    shareHolder: 'I am a shareholder only',
    officer: 'I am an director only',
    employee: 'I am an employee',
  },
};

export default i18n;
